.link {
  display: block;
  text-decoration: none;
  margin-top: space(2);
  height: space(10);
  display: flex;
  align-items: center;

  color: var(--theme-widget-on-primary-color) !important;
  background-color: var(--theme-widget-primary-color);

  margin: space(2) -10px -20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,.2),
              0 3px 4px 0 rgba(0,0,0,.14),
              0 1px 8px 0 rgba(0,0,0,.12);
}

.link svg {
  height: 100%;
  margin: 0 space(1);
}
